import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ballon from '../../Assets/Images/IMG-20240827-WA0006-removebg-preview (2).png'
import './Header.css'

function BasicExample() {
  return (
    <Navbar expand="lg" className="bg-black">
      <Container className='A'>
        <Navbar.Brand href="#home" className='AA'>
            <img src={ballon} className='AB'/>
            <h1>Solstice Credits</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color:"white"}} className='ZD' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" style={{color:"white"}}>Home</Nav.Link>
            <Nav.Link href="/About" style={{color:"white"}}>About</Nav.Link>
            <Nav.Link href="/Product" style={{color:"white"}}>Products</Nav.Link>
            <Nav.Link href="/Tokenomics" style={{color:"white"}}>Tokenomics</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default BasicExample;