import React from 'react'
import BasicExample from '../Header/Header'
import './Tokenomics.css'
import Token from '../../Assets/Images/tokenomics.png'
import ball from '../../Assets/Images/IMG-20240827-WA0006-removebg-preview (2).png'

const Tokenomics = () => {
  return (
    <div className='F'>
        <div className='FA'>   <BasicExample/>
            <div className='FB'><img src={Token} style={{height:"100%",width:"100%",objectFit:"contain"}}/></div>
            <div className='FC'>
                <h2 className='FD'>Tokenomics</h2>
                <p className='FE'>
                <div className='FF'><p className='FG'>WALLET:</p><p style={{marginLeft:"90px",textWrap:"wrap"}}>solstice wallet where you can safe your wallet</p></div>
                <div className='FF'><p className='FG'>NAME:</p><p style={{marginLeft:"100px"}}>Solstice Credit Token </p></div>
                <div className='FF'><p className='FG'>SYMBOL:</p><p style={{marginLeft:"85px"}}>SLC </p></div>
                <div className='FF'><p className='FG'>TOTAL SUPPLY:</p><p style={{marginLeft:"40px"}}>30,000,000</p></div>
                <div className='FF'><p className='FG'>DECIMAL:</p><p style={{marginLeft:"80px"}}>8</p></div>
                <div className='FF'><p className='FG'>CONTRACT <p>ADDRESS:</p></p><p className='FK'>0xC9ef56d537CBedd6D4F<p>B941339c4325901493d30</p></p>
                </div>
                {/* 0xC9ef56d537CB<p>edd6D4FB941339</p><p style={{marginTop:"-15px"}}>c4325901493d30</p> */}
                <div className='FF'><p className='FG'>TOKEN ALLOCATION:</p><div>
                                                                                <p className='FH'>40% BURNING (IN PARTS)</p>
                                                                                <p className='FH'>30% PRE – SALES</p>
                                                                                <p className='FH'>15% TEAM & FOUNDERS</p>
                                                                                <p className='FH'>5% DEVELOPMENT</p>
                                                                                <p className='FH'>5% MARKETING & COLLABORATION</p>
                                                                                <p className='FH'>4% AIRDROP</p>
                                                                                <p className='FH'>1% CHARITY</p>
                </div>
                </div>         
                </p>
            </div>
            <div className='G'>
                <h2 className='FD'>RoadMaps</h2>

                <div>
                <div className='GA'>
                <p>Phase-1:</p><div>
                                   <p>TOKEN CREATION</p>
                                   <p>WEBSITE LAUNCH</p>
                                   <p>WHITEPAPER LAUNCH</p>
                                   <p>PRESALES LAUNCH</p>             
                </div>
                </div>
                <div className='GA'>
                <p>Phase-2:</p><div>
                                   <p>MARKETING PUSH AWARENESS</p>
                                   <p>5000 TELEGRAM MEMBER</p>
                                   <p>5000 HOLDERS</p>
                                   <p>PRIMALX </p>
                                   <p>LAUNCH</p>
                                                
                </div>
                </div>
                <div className='GA'>
                <p>Phase-3:</p><div>
                                   <p>
                                   AIRDROP DISTRIBUTION</p>
                                   <p>
                                   INFLUENCER MARKETING PUSH</p>
                                   <p>LISTING ON COINMARKETCAP</p>
                                   <p>LISTING ON COINGECKO</p>             
                                   <p>10000 TELEGRAM MEMBER</p>
                                   <p>5000 HOLDERS</p>
                                   <p>
                                   THIRD PARTY AUDIT</p>
                </div>
                </div>
                <div className='GA'>
                <p>Phase-4:</p><div>
                                   <p>50,000 HOLDERS</p>
                                   <p>
                                   TOKEN BURN</p>
                                   <p>
                                   PRIMALSWAP LAUNCH</p>
                                             
                </div>
                </div>
                <div className='GA'>
                <p>Phase-5:</p><div>
                                   <p>150,000 HOLDERS</p>
                                   <p>TOKEN BURN</p>
                                   <p>
                                   NFT MARKET PLACE</p>
                                   <p>EXCHANGE LISTINGS</p>                                                
                </div>
                </div>
                <div className='GA'>
                <p>Phase-6:</p><div>
                                   <p>300,000 HOLDERS</p>
                                   <p>SLC LAUNCH GAMiNG </p>
                                   <p>(MANY MORE)</p>                                                
                </div>
                </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Tokenomics