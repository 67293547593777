import React from 'react'
import BasicExample from '../Header/Header'
import './Landing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faWhatsapp,faTelegram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import ball from '../../Assets/Images/recession-banking-statistics-bad-street-removebg-preview.png'


const Landing = () => {
  return (
    <div className='B'>
        <div className='BA'>
        <BasicExample/>
            <div className='BC'>
                <div className='BD'>
                    <h2 className='BE'>SOLSTICE CREDITS</h2>
                    <p className='BF'>Solstice Credits(SLC) is focused on building a decentralized cryptocurrency exchange marketplace and wallet, 
                        providing a secure and user-friendly platform for crypto enthusiasts.
                    <p className='TA'>Our goal is to facilitate partnerships
                        between cryptocurrency-adopted companies and Solstice Credits,
                        while also offering a reliable space for users to buy, sell, and securely store their crypto assets</p>
                        
                        </p>
                </div>
                <div className='BG'><img src={ball} style={{height:"100%",width:"100%",objectFit:"contain"}}/></div>
            </div>





            <div className='Y'>
        <div style={{display:"flex",gap:"10px"}}>
            <p>Channel</p>
        <a href='https://t.me/SolasticeCredits channel ' target='blank'><FontAwesomeIcon icon={faTelegram} beat style={{height:"30px",color:"white",width:"30px"}}/></a>        
        </div>
        <div style={{display:"flex",gap:"10px"}}>
            <p>Group</p>
        <a href='https://t.me/SolsticeCredits  ' target='blank'><FontAwesomeIcon icon={faTelegram} beat style={{height:"30px",color:"white",width:"30px"}}/></a>        
        </div>
        <div style={{display:"flex",gap:"10px"}}>
            <p>Twitter</p>
        <a href='https://x.com/credit_c15589/status/1810425842184704408' target='blank'><FontAwesomeIcon icon={faTwitter } beat style={{height:"30px",color:"white",width:"30px"}} /></a>
        </div>
        

        </div>



        </div>
       
        
    </div>
  )
}

export default Landing