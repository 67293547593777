import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Components/Home/Home'
import About from './Components/About/About'
import Product from './Components/Product/Product'
import Tokenomics from './Components/Tokenomics/Tokenomics'
import './App.css'

const App = () => {
  return (
    <div className='AO'>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/About' element={<About/>}/>
        <Route path='/Product' element={<Product/>}/>
        <Route path='/Tokenomics' element={<Tokenomics/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App