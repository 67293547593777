import React from 'react'
import Landing from '../Landing/Landing'
import './Home.css'
// import BasicExample from '../Header/Header'

const Home = () => {
  return (
    <div className='Z'>
        <Landing/>
        {/* <BasicExample/> */}
    </div>
  )
}

export default Home