import React from 'react'
import './About.css'
import BasicExample from '../Header/Header'
import bag from '../../Assets/Images/IMG-20240827-WA0006-removebg-preview (2).png'

const About = () => {
  return (
    <div className='L'>
             <BasicExample/>
        <div className='LA'>
            <div className='DB'>
                <h2 className='DC'>Solstice Credits (SLC)</h2>
                <p className='DD'>
                Solstice Credits(SLC) is focused on building a decentralized cryptocurrency exchange marketplace and wallet, 
                providing a secure and user-friendly platform for crypto enthusiasts.
                <p className='DE'>
                Our goal is to facilitate partnerships between cryptocurrency-adopted companies and Solstice Credits, 
                while also offering a reliable 
                space for users to buy, sell, and securely store their crypto assets
                </p>
                </p>
            </div>
            <div className='DF'><img src={bag} style={{height:"100%",width:"100%",objectFit:"contain"}}/></div>

        </div>
        <div className='C'>
            <h2 className='CA'>Why Solstice Credit?</h2>
            <p className='CB'> Solstice credits token is a Decentralised Token on the primal chain 

               Prc20. Each Trade has “Deflation, designed to become more scarce over time.
               <p style={{marginTop:"20px"}}>
            All holders of solstice credits token will earn more SLC that is automatically 
            sent to your wallet by simply holding solstice credits token in your wallet. Watch 
            the amount of SLC in your wallet as SLC token holders automatically receive a 5% fee 
            from every transaction that happens on the SLC network.
            </p>
            </p>
            <div className='CC'><p className='CD'>Reflection:</p><p className='CZ'>5% is reflected to all holders for passive income</p></div>
          <div className='CC'><p className='Cd'>LP Acquisition:</p><p className='CE'>5% is added to a liquidity pool</p></div>
        </div>

    </div>
  )
}

export default About