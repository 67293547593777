import React from 'react'
import './Product.css'
import BasicExample from '../Header/Header'
import cat from '../../Assets/Images/IMG-20240827-WA0006-removebg-preview (2).png'

const Product = () => {
  return (
    <div className='E'>
        <BasicExample/>
        <div className='EA'>
            <div className='EB'>
                <h2 className='EC'>Our Products</h2>
                <p className='ED'>
                Solstice Credits(SLC) intend to solve and provide solutions to the crypto world  by Providing a
                marketplace for all traders, Also providing great opportunities for crypto traders and upcoming crypto traders  Using our marketplace to
                promote and get famous while earning.

                <p className='EE'>
                With the use of $SLC as the mode of payment..
                <p className='EF'>
                We aim to provide Defi-tainment to the smart economy,where crypto lovers can get full optimality of their favourite project 
                and many more
                </p>
                </p>
                </p>
            </div>
            <div className='EG'><img src={cat} style={{height:"100%",width:"100%",objectFit:"contain"}}/></div>

        </div>

    </div>
  )
}

export default Product